/* Menu */
.header{
    @include principal-medium;
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: $secondary-color;
    transition: all .5s;
    border-bottom: $border;
    border-color: $secondary-color;

    &.header--scroll,
    &.header--scroll--menu{
        border-color: $principal-color;

        .header__title{
            opacity: 1;
        }
    }

    &.header--fixed{
        border-color: $principal-color;

        .header__title{
            opacity: 1;
        }
    }
    
    .header__title{
        font-size: 1.3em;
        text-transform: uppercase;
        letter-spacing: 2px;
        transition: all .5s;
        opacity: 0;
    }

    .header__button{
        font-size: 1.6em;
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 1em;
        width: 1em;
        cursor: pointer;
        
        .icon-x,
        .icon-align-right{
            position: absolute;
            transition: all .5s;
        }
        
        .icon-x{
            opacity: 0;
        }

        &.header__button--show{
            .icon-x{
                transform: rotate(360deg);
                opacity: 1;
            }
            .icon-align-right{
                transform: rotate(360deg);
                opacity: 0;
            }
        }
    }

    .header__menu{
        width: 100%;
        position: absolute;
        top: 54px;
        background-color: $secondary-color;
        z-index: 10;
        transition: all .7s;
        opacity: 0;
        left: 100%;

        &.header__menu--show{
            left: 0;
            opacity: 1;
        }

        &.header__menu--visible{
            opacity: 1;
        }

        .header__menu__list{
            list-style: none;
            padding: 0;

            .header__menu__item{
                cursor: pointer;

                &:hover{
                    background-color: #2f3136;
                    
                    a{
                        color: $secondary-color;
                    }
                }

                a{
                    display: block;
                    padding: 12px 20px;
                    text-decoration: none;
                    color: $principal-color;
                }
            }
        }
    }
}

/* Responsive */
@media (min-width: 640px) {
	header .button {
		display: none;
	}
	header .nav{
		right: 0;
		display: block;
	}
}