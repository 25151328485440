// Secciones

section{
    margin-top: 70px;
    padding: 20px;

    .section__title{
        @include title-section('black');
    }

    .section__paragraph{
        @include text-section;
        color: $principal-color;
    }
}

// Media Queries

@media screen and (min-width: $bkp-md) {
    section{
        margin: 30px auto;
        max-width: 850px;
        padding-top: 50px;
    }
}