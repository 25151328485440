// Footer

.footer{
    background-color: $principal-color;
    color: $secondary-color;
    text-align: center;
    padding: 50px 20px;
    margin-top: 30px;

    .footer__name{
        text-transform: uppercase;
        letter-spacing: 5px;
        @include principal-regular;
        font-size: 1.5em;
    }
    
    .footer__mail{
        @include principal-regular;
        margin: 5px 0;
        
        span, i{
            font-style: normal;
            font-size: 1.3em;
        }
    }

    .footer__icons{
        display: flex;
        justify-content: center;
        margin-top: 15px;

        a{
            text-decoration: none;
            color: $secondary-color;
        }
        
        .footer__icon{
            font-size: 2em;
            margin:10px;
        }
    }
}