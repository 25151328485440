.load-wrapp {
	width: 100px;
	height: 100px;
	margin: 0 auto;
	padding: 20px 20px 20px;
	border-radius: 5px;
	text-align: center;
}

.square {
	width: 20px;
	height: 20px;
	border-radius: 4px;
	background-color: #000;
	margin: 20px 0;
}

.load .square {
    animation: loading 1.5s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

@keyframes loading {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    50% {
        transform: translate(70px, 0) rotate(360deg);
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}