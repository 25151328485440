// Disclaimer

.disclaimer{
	border: $border;
	border-width: 3px;
	padding: 15px;
	text-align: center;
	margin-bottom: 20px;

	.disclaimer__title{
		@include principal-regular;
		font-size: 1em;
		text-transform: uppercase;
		position: relative;
		display: inline-block;

		&:after{
			content: '';
			position: absolute;
			width: 170%;
			height: 1px;
			background-color: $principal-color;
			bottom: 0;
			left: -35%;
		}
	}

	.disclaimer__text{
		@include secondary-regular;
		font-size: 1em;
		margin-top: 15px;
	}
}