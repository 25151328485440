// Cards

.cards{
	display: flex;
	justify-content: flex-start;
    flex-wrap: wrap;
    opacity: 0;
    transition: .5s all;
    overflow-x: hidden;

	.card{
		background-color: $principal-color;
		padding: 7px;
		margin-bottom: 25px;
		width: 100%;

		.card__image{
			object-fit: cover;
			object-position: center;
			width: 100%;

			&.card__image--book{
				padding: 15px 15% 15px;
			}
		}

		.card__content{
			padding: 10px;

			.card__title{
				@include principal-regular;
				font-size: 1.3em;
				color: $secondary-color;
				text-transform: uppercase;
			}
	
			.card__subtitle{
				@include secondary-regular;
				font-size: 1em;
				color: $secondary-color;
				text-transform: capitalize;
			}
	
			.card__line{
				width: 50%;
				height: 1px;
				background-color: $secondary-color;
				margin-top: 5px;
				margin-bottom: 20px;
			}
	
			.card__paragraph{
				@include text-section;
				line-height: 18px;
			}
	
			.card__buttons{
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				margin-top: 25px;

				&.card__butttons--articles{
					justify-content: flex-end;

					.card__button{
						margin: 0;
					}
				}
	
				.card__button{
					padding: 5px;
					border: $border;
					border-color: $secondary-color;
					margin-right: 10px;

					a{
						@include principal-light;
						font-size: 1em;
						text-decoration: none;
						color: $secondary-color;
						letter-spacing: 2px;
					}
	
				}
			}
	
			.card__list{
				margin-top: 25px;
				list-style: none;
				padding: 0;
				text-transform: capitalize;
				
				.card__list__item{
					@include secondary-regular;
					color: $secondary-color;
					font-size: 1.1em;
	
					span{
						color: $text-color;
						font-size: 1em;
						margin-right: 7px;
					}
				}
	
			}
		}
    }
    
    &.fade-in{
        opacity: 1;
    }
}

// Responsive

@media screen and (min-width: $bkp-md) {
	.cards{
		margin-top: 30px;
		
		.card{
			display: flex;

			.card__image{
				width: 50%;
			}
	
			.card__content{
				width: 50%;
				padding: 15px;
			}

			&:nth-child(odd){
				flex-flow: row-reverse;
			}
		}

		&.cards--articles{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.card{
				width: 49%;
				margin-bottom: 15px;

				.card__content{
					width: 100%;
				}
			}
		}
	}
}

@media screen and (min-width: $bkp-xl) {
	.cards{

		&.cards--articles{

			.card{
				width: 33%;
			}
		}
	}
}