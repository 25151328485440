// Presentation

.presentation{
    
    .presentation__card{
        background-color: $principal-color;
        padding: 10px;

        .presentation__image{
            min-height: 50vh;
            width: 100%;
            background-image: url('../img/foto-perfil.jpg');
            background-size: cover;
            background-position: center;
        }
    
        .presentation__text{
            padding: 20px 0;
    
            .presentation__title{
                @include title-section('white');
            }
    
            .presentation__paragraph{
                @include text-section;
            }
        }
    }
}

// Media Queries

@media screen and (min-width: $bkp-md) {
    .presentation{
        
        .presentation__card{
            padding: 7px;
            display: flex;
            
            .presentation__image{
                width: 50%;
                min-height: auto;
            }
    
            .presentation__text{
                width: 50%;
                padding: 20px;
            }
        }
    }
}