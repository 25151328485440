/* Portada */

.landing {
    margin-top: 20px;

    .landing__container{
        position: relative;
        width: 100%;
        min-height: 95vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 50px 0;
        text-align: center;

        h1 {
            @include principal-bold;
            font-size: 17vw;
            text-transform: uppercase;
            line-height: 1em;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-image: url('https://images.unsplash.com/photo-1461749280684-dccba630e2f6?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80');
            background-size: cover;
        }

        h2 {
            @include secondary-medium;
            font-size: 6vw;
            font-weight: 700;
        }
    }
}

// Media Queries

@media screen and (min-width: $bkp-lg) {
    .landing{

        .landing__container{

            h1{
                font-size: 12vw;
            }

            h2{
                font-size: 4vw;
            }
        }
    }
}