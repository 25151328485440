// Skills

.skills{
    padding: 20px;

    .skills__title{
        @include title-section('black');
    }

    .skills__buttons{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .skills__button{
            padding: 10px 15px;
            border: $border;
            border-width: 2px;
            text-transform: uppercase;
            @include principal-regular;
            font-size: 1.3em;
            margin: 10px;
        }
    }
}