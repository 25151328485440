// Variables

// Beakpoints
$bkp-sm: 640px;
$bkp-md: 768px;
$bkp-lg: 1024px;
$bkp-xl: 1280px;

// Fonts
$principal: 'Oswald', sans-serif;
$secondary: 'Poppins', sans-serif;

// Colors
$principal-color: #000;
$secondary-color: #fff;
$text-color: #ABABAB;

// Borders
$border: 1px solid $principal-color;

// ----------------------------------------------------------------

// Fonts Mixin

@mixin principal-light{
	font-family: $principal;
	font-weight: 200;
}
@mixin principal-regular{
	font-family: $principal;
	font-weight: 400;
}
@mixin principal-medium{
	font-family: $principal;
	font-weight: 500;
}
@mixin principal-bold{
	font-family: $principal;
	font-weight: 700;
}
@mixin secondary-regular{
	font-family: $secondary;
	font-weight: 400;
}
@mixin secondary-medium{
	font-family: $secondary;
	font-weight: 500;
}

// ----------------------------------------------------------------

// Component Mixins

@mixin title-section($color){ //'black' or 'white'
	@include principal-regular;
	font-size: 1.4em;
	width: 100%;
	position: relative;
	padding: 7px 0px;
	letter-spacing: 1px;
	margin-bottom: 25px;
	
	@if($color == 'black'){
		color: $principal-color;
	}
	@if($color == 'white'){
		color: $secondary-color;
	}

	&:after{
		content: '';
		position: absolute;
		width: 40%;
		height: 1px;
		bottom: 0;
		left: 0;

		@if($color == 'black'){
			background-color: $principal-color;
		}
		@if($color == 'white'){
			background-color: $secondary-color;
		}
	}
}

@mixin text-section{
	@include secondary-regular;
	color: $text-color;
	font-size: 1em;
	margin-bottom: 1em;

	a{
		color: $secondary-color;
	}
}

// ----------------------------------------------------------------

// Global styles
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	// font-family: $secondary;
    outline: none;
}

//Helpers
.d-none{
	display: none;
}

.fade-in .card{
	animation: fade-in .5s ease-in-out;
}

@keyframes fade-in{
	0%{
		transform: translateX(50px);
	}
	100%{
		transform: translateX(0px);
	}
}